<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
            <h2 class="text-2xl font-weight-semibold">
              Империя пиццы
            </h2>
          </router-link>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="userName"
              outlined
              :rules="[rules.required]"
              label="Логин"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>
            <v-text-field
              v-model="password"
              outlined
              :rules="[rules.required]"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Пароль"
              placeholder="············"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Запомнить"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
            </div>
            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="submitSignIn"
            >
              Войти
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {rules} from '@/helpers/validation'

export default {
  data: () => ({
    isPasswordVisible: false,
    userName: '',
    password: '',
    rules
  }),
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      auth: state => state.auth.auth,
    }),
  },
  watch: {
    auth: async function() {
      const access_token = localStorage.getItem('access_token')
      if (access_token) {
        await this.$router.push({ name: 'dashboard' })
      }
    },
  },
  methods: {
    async submitSignIn() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('auth/signIn', { userName: this.userName, password: this.password })
        try {
          await this.$store.dispatch('auth/getUserData')
        } catch (e) {
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/core/preset/preset/pages/auth.scss';
</style>
